import React, { useEffect, useState } from 'react';
import DraggableCore from 'react-draggable';
import { FormattedMessage } from "react-intl";
import SimpleLocalize from "../../components/SimpleLocalize";
import './style.scss';

const Heliofania = (props) => {

    const [anchoImagen, setAnchoImagen] = useState(null);
    const [anchoCosito, setAnchoCosito] = useState(null);
    const [finDrageo, setFinDrageo] = useState(0);
    const [seccionSeleccionada, setSeccionSeleccionada] = useState('temperaturas');
    const cantidadDeFrames = 77;

    useEffect(() => {
        setAnchoImagen(document.getElementById("photo-wrapper").offsetWidth);
    }, [])

    useEffect(() => {
		var viewport = document.getElementsByTagName('body')[0];
		const checkPos = (e) => {
			var a = viewport.scrollTop;
			var b = viewport.scrollHeight - viewport.clientHeight;
			var c = a / b;
			if (c === 0.4 && document.querySelector("#dragger-msg")) {
				document.querySelector("#dragger-msg").classList.add("image_01_animate");
                setTimeout(()=>{document.querySelector("#dragger-msg").classList.add("fadeoutclass")},10000)
			}
		}
		viewport.addEventListener('scroll', checkPos, false);
	},[])

    useEffect(() => {

        /*console.log(anchoImagen)
        window.addEventListener('DOMContentLoaded', () => {

        });*/
        if (anchoImagen !== null) {
            setAnchoCosito(anchoImagen / cantidadDeFrames);
            setFinDrageo(anchoImagen - ((anchoImagen / cantidadDeFrames) * 2));
            let posController = (anchoImagen - ((anchoImagen / cantidadDeFrames) * 2)) / (anchoImagen / cantidadDeFrames)
            let initial = posController * anchoImagen;
            document.getElementById("photo-wrapper").style.transform = "translate(-" + initial + "px)"
        }
    }, [anchoImagen]);

    const getPosicion = () => {
        const anchodeController = anchoCosito;
        let transf = document.getElementById("draggerHandler").style.transform;
        let substr = transf.substring(transf.indexOf("(") + 1, transf.lastIndexOf("px,"));
        let posController = Number(substr) / anchodeController;
        let initial = posController * anchoImagen;
        document.getElementById("photo-wrapper").style.transform = "translate(-" + initial + "px)"
        setFinDrageo(Number(substr));
    }

    return (
        <SimpleLocalize {...props}>
            <section className="py-0 text-center position-relative min-vh-100">
                <div className="bg-holder" style={{ background: "#FFF" }}>
                    <div className="min-vh-100 content-wrapper container">
                        <div className="row">
                            <div className="min-vh-100 col-md-8 mt-6">
                                <div className={seccionSeleccionada == 'temperaturas' ? ('d-block') : ('d-none')} >
                                    <div className="heliofania-wrapper">
                                        <div className="d-flex" id="photo-wrapper">
                                            {Array.from(Array(cantidadDeFrames), (e, i) => {
                                                let num = cantidadDeFrames - i;

                                                if (num < 10) {
                                                    num = "0" + num;
                                                }
                                                return <img key={i} src={props.pageContext.language == 'en' ? '../../helofania/frame_' + num + '.jpg' : '../helofania/frame_' + num + '.jpg'} alt="" className='img-fluid' />
                                            })
                                            }
                                        </div>
                                    </div>
                                    <div className='dragger-wrapper'>
                                        <DraggableCore
                                            axis="x"
                                            handle=".handle"
                                            defaultPosition={{ x: finDrageo, y: 0 }}
                                            position={{ x: finDrageo, y: 0 }}
                                            grid={[anchoCosito, anchoCosito]}
                                            scale={1}
                                            bounds={{ top: -100, left: 0, right: anchoImagen - (anchoCosito * 2), bottom: 100 }}
                                            onDrag={getPosicion}
                                            onStop={getPosicion}>
                                            <div className="handle" id="draggerHandler"></div>
                                        </DraggableCore>
                                        <div className='dragger-msg' id='dragger-msg'>
                                            <div class="arrow">
                                                <span></span>
                                                <span></span>
                                                <span></span>
                                            </div>
                                            drag to see
                                        </div>
                                        <img src={props.pageContext.language == 'en' ? "../../heliofania_barra.jpg" : "../heliofania_barra.jpg"} alt="" className='dragger-container' style={{ width: anchoImagen - (anchoCosito) }} />
                                        <div className='time-container d-flex justify-content-between mt-2' style={{ fontWeight: 'bold', fontSize: '20px' }}>
                                            <div className='time-container-item font-weight-bold'>PM</div>
                                            <div className='time-container-item font-weight-bold'>AM</div>
                                        </div>
                                    </div>
                                </div>
                                <div className={seccionSeleccionada == 'precipitaciones' ? ('d-block') : ('d-none')} >
                                    <img src={props.pageContext.language == 'en' ? "../../radiacionsolar_km_m2_en.jpg" : "../radiacionsolar_km_m2.jpg"} alt="" className='img-fluid' />
                                </div>
                            </div>
                            <div className="col-md-4 text-start mt-6 texto-con-scroll">
                                <h1 className="title-2 mt-0"><FormattedMessage id="heliofania-title" defaultMessage="heliofania-title" /></h1>
                                <nav className="navbar-expand-lg navbar-light">
                                    <div id="navbarNavAltMarkup">
                                        <div className="navbar-nav">
                                            <a className={seccionSeleccionada == 'temperaturas' ? "nav-item nav-link active" : "nav-item nav-link unactive"} onClick={() => setSeccionSeleccionada('temperaturas')}>
                                                <FormattedMessage id="orientacion-uppercase" defaultMessage="orientacion-uppercase" />
                                            </a>
                                            <a className={seccionSeleccionada == 'precipitaciones' ? "nav-item nav-link active" : "nav-item nav-link unactive"} onClick={() => setSeccionSeleccionada('precipitaciones')}>
                                                <FormattedMessage id="radiacion-solar-uppercase" defaultMessage="radiacion-solar-uppercase" />
                                            </a>
                                        </div>
                                    </div>
                                </nav>
                                <p>
                                    <FormattedMessage id="nuestros-viniedos-en-espalderos" defaultMessage="nuestros-viniedos-en-espalderos" />.
                                </p>
                                <p>
                                    <FormattedMessage id="luego-durante-las-horas-mas-fuertes" defaultMessage="luego-durante-las-horas-mas-fuertes" />.
                                </p>
                                <p className='mb-5'>
                                    <FormattedMessage id="en-las-tardes-la-radiacion" defaultMessage="en-las-tardes-la-radiacion" />.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </SimpleLocalize>
    );
};

export default Heliofania;