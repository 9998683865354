import React from "react"

import Layout from "../components/layout"

import img01 from "../images/clima-01.jpg"
import img02 from "../images/clima-02.jpg"

import Heliofania from "../components/clima/heliofania"
import { TemperaturasDelValle } from "../components/clima/temperaturasdelvalle"
import { CiclosVegetativos } from "../components/clima/ciclosvegetativos"

import { FormattedMessage } from "react-intl";
import SimpleLocalize from "../components/SimpleLocalize";


const IndexPage = (props) => {


	return <Layout {...props}>
		<SimpleLocalize {...props}>
			<section className="py-0 text-center position-relative min-vh-100">
				<div className="bg-holder header-video" style={{ backgroundImage: "url(" + img01 + ")" }}>
					<div className="video-wrapper position-absolute w-100 h-100">
						<iframe
							title="video introduction"
							frameBorder="0"
							height="100%"
							width="100%"
							src="https://youtube.com/embed/STArIHUqHiU?autoplay=1&controls=0&showinfo=0&autohide=1&rel=0&loop=1&mute=1&playlist=STArIHUqHiU&modestbranding=1"
						>
						</iframe>
					</div>
					<div className="min-vh-100 content-wrapper d-flex flex-column justify-content-center text-white container">
						<div style={{ "width": "100%", "height": "100vh", "background": "transparent", "position": "absolute", "zIndex": "1", "left": "0" }}></div>
						<h1 style={{ "zIndex": "2" }}><FormattedMessage id="clima-title" defaultMessage="clima-title" /></h1>
						<p style={{ "zIndex": "2" }} className="col-8 offset-2 text-center">
							<FormattedMessage id="nuestra-finca-cuenta-con-un-clima-excepcional" defaultMessage="nuestra-finca-cuenta-con-un-clima-excepcional" />.
						</p>
					</div>
				</div>
			</section>
			<section className="py-0 text-center position-relative min-vh-100">
				<div className="bg-holder" style={{ background: "#FFF" }}>
					<div className="min-vh-100 content-wrapper container">
						<div className="row">
							<div className="min-vh-100 col-md-4 text-start mt-6 texto-con-scroll-mas-altura">
								<h1 className="title-2 mb-3" style={{ maxWidth: '100%' }}><FormattedMessage id="clima-del-valle-de-uco" defaultMessage="clima-del-valle-de-uco" /></h1>
								<h4><FormattedMessage id="nuestro-clima" defaultMessage="nuestro-clima" /></h4>
								<p><FormattedMessage id="un-viniedo-ubicado-en-una-latitud" defaultMessage="un-viniedo-ubicado-en-una-latitud" />.</p>
								<p><FormattedMessage id="de-grado-winkler" defaultMessage="de-grado-winkler" />.</p>
								<p><FormattedMessage id="de-una-amplitud-termina" defaultMessage="de-una-amplitud-termina" />.</p>
								<p><FormattedMessage id="gracias-a-esta-amplitud" defaultMessage="gracias-a-esta-amplitud" />.</p>
							</div>
							<div className="min-vh-100 col-md-8 mt-6">
								<img src={img02} alt="" className="img-fluid" />
							</div>
						</div>
					</div>
				</div>
			</section>
			<Heliofania {...props} />
			<TemperaturasDelValle {...props} />
			<CiclosVegetativos {...props} />
		</SimpleLocalize>
	</Layout>
}

export default IndexPage