import React, { useState } from 'react';
import { FormattedMessage } from "react-intl";
import SimpleLocalize from "../../components/SimpleLocalize";

export const TemperaturasDelValle = (props) => {
    let camino = '..';
    let poslang = '';
    if(props.pageContext.language == 'en'){
        camino = '../..';
        poslang = "_en";
    }
    const items = [
        { img: camino+'/temperaturadelvalle/temperatura_2018-2019'+poslang+'.jpg', label: "2018-2019" },
        { img: camino+'/temperaturadelvalle/temperatura_2019-2020'+poslang+'.jpg', label: "2019-2020" },
        { img: camino+'/temperaturadelvalle/temperatura_2020-2021'+poslang+'.jpg', label: "2020-2021" },
        { img: camino+'/temperaturadelvalle/temperatura_2021-2022'+poslang+'.jpg', label: "2021-2022" }
    ]
    const [activeIndex, setActiveIndex] = useState(0);

    const handleOnClick = (index) => {
        setActiveIndex(index);
    }
    return (
        <SimpleLocalize {...props}>
            <section className="py-0 text-center position-relative min-vh-100">
                <div className="bg-holder bg-white no-top">
                    <div className="min-vh-100 container">
                        <div className="row">
                            <div className="col-md-4 text-start pt-4 texto-con-scroll">
                                <h1 className="title-2 mt-3 pt-2"><em><FormattedMessage id="temperatura-del-valle" defaultMessage="temperatura-del-valle" /></em></h1>
                                <hr />
                                <nav className="navbar-expand-lg navbar-light">
                                    <div id="navbarNavAltMarkup">
                                        <div className="navbar-nav">
                                            {items.map((item, index) => {
                                                return (<a key={index} className={activeIndex == index ? "nav-item nav-link active" : "nav-item nav-link unactive"} onClick={() => handleOnClick(index)}>{item.label}</a>)
                                            })}
                                        </div>
                                    </div>
                                </nav>
                                <p className="mt-4">
                                    <FormattedMessage id="si-bien-cada-anio" defaultMessage="si-bien-cada-anio" />.
                                </p>
                                <p>
                                    <FormattedMessage id="las-temperaturas-maximas" defaultMessage="las-temperaturas-maximas" />.
                                </p>
                                <p>
                                    <FormattedMessage id="las-temperaturas-medias-no-superan" defaultMessage="las-temperaturas-medias-no-superan" />.
                                </p>
                                <p>
                                    <FormattedMessage id="las-bajas-temperaturas-durante" defaultMessage="las-bajas-temperaturas-durante" />.
                                </p>
                            </div>
                            <div className="col-md-8 mt-5">
                                {items.map((item, index) => {
                                    return (<img key={index} className={activeIndex == index ? "d-block img-fluid" : "d-none"} src={item.img} />)
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </SimpleLocalize>
    );
};
